import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/homePage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/visitorInformation',
    component: () => import('@/views/visitorInfoPage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/openingTimes',
    component: () => import('@/views/openingTimesPage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/events',
    component: () => import('@/views/eventsPage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/tours',
    component: () => import('@/views/toursPage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/route',
    component: () => import('@/views/routePage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/impressum',
    component: () => import('@/views/impressumPage.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/datenschutz',
    component: () => import('@/views/datenschutzPage.vue'),
    meta: { transition: 'slide-right' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
